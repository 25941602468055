define("discourse/plugins/poll/discourse/components/poll-results-pie", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/template", "ember-modifier", "discourse/lib/load-script", "discourse/plugins/poll/lib/chart-colors", "discourse/plugins/poll/discourse/components/modal/poll-ui-builder", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didInsert, _template, _emberModifier, _loadScript, _chartColors, _pollUiBuilder, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PollResultsPieComponent extends _component.default {
    htmlLegendPlugin = {
      id: "htmlLegend",
      afterUpdate(chart, args, options) {
        const ul = document.getElementById(options.containerID);
        if (!ul) {
          return;
        }
        ul.innerHTML = "";
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
        items.forEach(item => {
          const li = document.createElement("li");
          li.classList.add("legend");
          li.onclick = () => {
            chart.toggleDataVisibility(item.index);
            chart.update();
          };
          const boxSpan = document.createElement("span");
          boxSpan.classList.add("swatch");
          boxSpan.style.background = item.fillStyle;
          const textContainer = document.createElement("span");
          textContainer.style.color = item.fontColor;
          textContainer.innerHTML = item.text;
          if (!chart.getDataVisibility(item.index)) {
            li.style.opacity = 0.2;
          } else {
            li.style.opacity = 1.0;
          }
          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        });
      }
    };
    stripHtml = html => {
      let doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };
    pieChartConfig = (() => {
      var _this = this;
      return function (data, labels) {
        let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        const aspectRatio = "aspectRatio" in opts ? opts.aspectRatio : 2.2;
        const strippedLabels = labels.map(l => _this.stripHtml(l));
        return {
          type: _pollUiBuilder.PIE_CHART_TYPE,
          data: {
            datasets: [{
              data,
              backgroundColor: (0, _chartColors.getColors)(data.length)
            }],
            labels: strippedLabels
          },
          plugins: [_this.htmlLegendPlugin],
          options: {
            responsive: true,
            aspectRatio,
            animation: {
              duration: 0
            },
            plugins: {
              legend: {
                labels: {
                  generateLabels() {
                    return labels.map((text, index) => {
                      return {
                        fillStyle: (0, _chartColors.getColors)(data.length)[index],
                        text,
                        index
                      };
                    });
                  }
                },
                display: false
              },
              htmlLegend: {
                containerID: opts?.legendContainerId
              }
            }
          }
        };
      };
    })();
    registerLegendElement = (() => (0, _emberModifier.modifier)(element => {
      this.legendElement = element;
    }))();
    registerCanvasElement = (() => (0, _emberModifier.modifier)(element => {
      this.canvasElement = element;
    }))();
    get canvasId() {
      return (0, _template.htmlSafe)(`poll-results-chart-${this.args.id}`);
    }
    get legendId() {
      return (0, _template.htmlSafe)(`poll-results-legend-${this.args.id}`);
    }
    async drawPie() {
      await (0, _loadScript.default)("/javascripts/Chart.min.js");
      const data = this.args.options.mapBy("votes");
      const labels = this.args.options.mapBy("html");
      const config = this.pieChartConfig(data, labels, {
        legendContainerId: this.legendElement.id
      });
      const el = this.canvasElement;
      // eslint-disable-next-line no-undef
      this._chart = new Chart(el.getContext("2d"), config);
    }
    static #_ = (() => dt7948.n(this.prototype, "drawPie", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="poll-results-chart">
          <canvas
            {{didInsert this.drawPie}}
            {{didInsert this.registerCanvasElement}}
            id={{this.canvasId}}
            class="poll-results-canvas"
          ></canvas>
          <ul
            {{didInsert this.registerLegendElement}}
            id={{this.legendId}}
            class="pie-chart-legends"
          >
          </ul>
        </div>
      
    */
    {
      "id": "Hhwt+W5n",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"poll-results-chart\"],[12],[1,\"\\n      \"],[11,\"canvas\"],[16,1,[30,0,[\"canvasId\"]]],[24,0,\"poll-results-canvas\"],[4,[32,0],[[30,0,[\"drawPie\"]]],null],[4,[32,0],[[30,0,[\"registerCanvasElement\"]]],null],[12],[13],[1,\"\\n      \"],[11,\"ul\"],[16,1,[30,0,[\"legendId\"]]],[24,0,\"pie-chart-legends\"],[4,[32,0],[[30,0,[\"registerLegendElement\"]]],null],[12],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/components/poll-results-pie.js",
      "scope": () => [_didInsert.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PollResultsPieComponent;
});